@import url("https://fonts.googleapis.com/css2?family=Mate:ital@0;1&display=swap");

.random-quote {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.random-quote.fade-out {
  opacity: 0;
}
