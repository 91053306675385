@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");

.magazines-page {
  background-image: url(../../public/images/backgroundMAGAZINES.svg);
  background-position: center;
  background-size: cover;
  color: #0e2b54;
  min-height: 100vh; /* Allows the content to extend beyond the viewport */
  width: 100vw;
  overflow-x: hidden; /* Prevents horizontal overflow */
  overflow-y: auto; /* Allows vertical scrolling */
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  box-sizing: border-box;
}

.navbar {
  position: relative;
  width: 100%;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  width: 100%;
}

.dropdown-item {
  padding: 25px 25px 25px 25px;
  float: left;
  width: 50%;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 1440px) {
  .dropdown-item {
    width: 100%; 
    height: auto; 
    padding: 25px 25px 25px 25px;

  }
}