.day,
.date,
.time {
  font-size: 1.75em;
}

.day, .time {
  padding-left: 1em;
}

@media screen and (max-width: 430px) {
  .day,
  .date,
  .time {
    font-size: 1.4em;
    padding-left: .8em;
  }
}
