@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap");

.guide-head {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  z-index: 0;
}

.guide-head .guide-title {
  padding-top: 30px;
  padding-left: 50px;
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 4rem;
  flex: 1 1 85%;
  overflow: hidden;
  color: #0e2b54;
  text-shadow: 1px 1px 2px #0e2b54;
}

.guide-head .guide-date {
  padding-top: 30px;
  padding-right: 50px;
  display: flex;
  justify-content: right;
  align-items: right;
  font-size: 2rem;
  flex: 1 1 15%;
  overflow: hidden;
  color: #0e2b54;
}

.guide-blurb {
  padding-top: 30px;
  padding-left: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 2rem;
  overflow: hidden;
  color: #0e2b54;
}

.guide-card {
  background-color: transparent;
  border-style: solid;
  border-radius: 30px;
  border-color: #0e2b54;
  border-width: 5px;
}

.guide-card:hover {
  background-color: #a8a6cd;
}

@media screen and (max-width: 430px) {
  .guide-head {
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
  }

  .guide-head .guide-title {
    padding-top: 20px;
    padding-left: 0px;
    font-size: 2rem;
  }

  .guide-head .guide-date {
    padding-top: 10px; /* Adjust padding for date */
    padding-right: 0px; /* Remove right padding */
    padding-left: 0px; /* Align with title padding */
    font-size: 1.5rem; /* Smaller font size for smaller screens */
  }

  .guide-blurb {
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
    font-size: 1.25rem; /* Smaller font size for smaller screens */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    text-align: center;
  }
}
