@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mate:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");

/* HOME PAGE  */

.home-page-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.home-page {
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-color: #ede8f5;
  color: #444484;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 150px;
}

.home-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.navbar {
  position: relative;
  width: 100%;
  z-index: 1;
}

.wtc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
}

.design {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: auto;
  animation-name: leftwardsSlide;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  max-width: 1000px;
}

.svg1 {
  max-width: 100%;
  max-height: 100%;
  margin-left: -1.5em;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.svg2 {
  max-width: 100%;
  max-height: 100%;
  margin-left: -1.5em;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.title-design {
  float: left;
  display: flex;
  flex-direction: row;
  padding-bottom: 15em;
}

/* TITLE  */
.title {
  animation-name: rightwardsSlide;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-between;
  flex-direction: column;
  padding-right: 10em;
  width: 100%;
}

.header {
  padding-top: 50px;
  justify-content: space-between;
  flex-direction: column;
  max-width: 60%;
}

.title .typography {
  font-size: 13rem;
  font-family: "Cinzel Decorative";
  letter-spacing: -10px;
  font-weight: 700;
  padding-left: 100px;
}

.backgroundInsert1 {
  background-image: url(../../public/images/svg3.png);
  margin-left: -1.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}
.backgroundInsert2 {
  background-image: url(../../public/images/svg4.png);
  margin-left: -1.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.slideshow {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 50px;
  z-index: 0;
}

@media screen and (max-width: 430px) {
  .slideshow {
    padding-bottom: 0px;
  }
}

.slideshow-carousel {
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 50%;
  z-index: 0;
}

/* SCHEDULE + CALENDAR  */
.schedule-calendar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  z-index: 0;
}

.left-section {
  border-radius: 25px;
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 7.5em;
  padding-right: 2em;
}

.left-section .typography {
  margin-left: 1em;
}

.calendar-section {
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 7.5em;
  padding-left: 2em;
}

.schedule {
  margin-left: 0.57em;
}
.schedule-title,
.calendar-title {
  font-size: 5rem;
  font-family: "Lora" !important;
  text-align: center;
}

.left-side,
.calendar {
  border-radius: 25px;
  flex: 1 1 50%;
  box-sizing: border-box;
  border: 3px solid #0e2b54;
  gap: 2px;
  padding: 0;
  margin: 0;
  line-height: 1;
  overflow: hidden;
  padding-right: 2em;
}

.date,
.schedule {
  padding-top: 10px;
  font-size: 2rem;
  text-align: center;
}

/* MAGAZINE */
.magazine-design {
  display: flex;
  justify-content: center;
  width: 100%;
}

.magazine-container {
  box-sizing: border-box; /* Ensure padding is included in the width */
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
}

.magazine-border {
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
  border-width: 3px;
  border-color: #0e2b54;
  justify-content: center;
}

.magazine {
  padding: 27px;
  width: 100%; /* Adjust the width of the magazine content within the container */
  height: auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.magazine-title {
  display: flex;
  font-size: 5rem;
  font-family: "Lora" !important;
  padding-bottom: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.design-container {
  padding-top: 500px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  margin: 0, auto;
  width: 100%;
  height: auto;
  box-sizing: border-box; /* Ensure padding is included in the width */
  gap: 2px;
}

.stuyvesant-image {
  width: 50%; /* Adjust the width of the magazine content within the container */
  height: auto;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  object-fit: contain;
}

/* FOOTER  */
.footer {
  font-family: "Lora";
  background-color: white;
  text-align: center;
  margin-top: auto; /* Pushes the footer to the bottom */
  width: 100%;
  -webkit-text-stroke: 0.2px white;
}

.blurb {
  animation-name: leftwardsSlide;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  font-size: 1.5rem;
  text-align: center;
  font-style: italic;
  font-weight: 400;
  padding-top: 50px;
}

.blurb-design {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
/* ANIMATIONS  */
@keyframes downwardsSlide {
  from {
    transform: translateY(-600px);
    filter: blur(5px);
  }
  to {
    transform: translateY(0);
    filter: blur(0);
  }
}
@keyframes rightwardsSlide {
  from {
    transform: translateX(-600px);
    filter: blur(5px);
  }
  to {
    transform: translateY(0);
    filter: blur(0);
  }
}
@keyframes leftwardsSlide {
  from {
    transform: translateX(600px);
    filter: blur(5px);
  }
  to {
    transform: translateY(0);
    filter: blur(0);
  }
}

@media screen and (max-width: 430px) {
  .header .title .typography {
    font-size: 3.5em;
    padding-left: 4em;
    letter-spacing: -7.5px;
  }

  .title-design {
    flex-direction: column;
    padding-bottom: 5em;
  }

  .design {
    width: 20em;
  }
  .blurb {
    width: 15em;
  }

  .home-page {
    padding-top: 9em;
  }

  .schedule-calendar {
    flex-direction: column;
  }

  .left-section {
    border-radius: 25px;
    flex: 1 1 50%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-left: 2em;
    padding-right: 0.5em;
  }

  .calendar-section {
    flex: 1 1 50%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-right: 0.5em;
    padding-left: 2em;
    padding-top: 4em;
  }

  .schedule-title,
  .calendar-title,
  .magazine-title {
    font-size: 5rem !important;
  }

  .stuyvesant-image {
    width: 95%; /* Adjust the width of the magazine content within the container */
    padding-top: 5em;
    padding-left: 1.5em;
  }

  .magazine-container {
    width: 95%;
    padding-left: 1.5em;
  }
}
