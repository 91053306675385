@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");

.navbar {
  position: relative;
  width: 100%;
  z-index: 1;
}

.newslettersContent {
  background-image: url(../../public/images/backgroundNEWSLETTERS.svg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  padding: 50px;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

/* NEWSLETTERS PAGE */
.newsletters-page {
  color: #0e2b54;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.newsletters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  max-width: 85em;
  margin: 0 auto;
}

.newsletters-container {
  padding-top: 100px;
}

/* TITLE */
.newsletters-container .newsletters-title {
  font-family: "Baskervville SC" ;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  margin: auto;
  overflow: hidden;
  animation: typing 2s steps(200, end);
}

.newsletters-title {
  border-top: 5px ridge #444484;
  border-bottom: 5px ridge #444484;
  border-left: none;
  border-right: none;
}

.latest-title {
  padding-top: 4rem;
  font-family: "Baskervville SC" !important;
  text-decoration: none;
  padding-bottom: 1.5rem;
}

/* LIST STYLES */
.latest-title-div {
  display: flex;
  justify-content: center;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  animation: fadeInAnimation ease 4s;
  margin-bottom: 20px;
}

.latest-embed-blurb-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 35px;
  background-color: #a8a6cd;
  color: #0e2b54;
  margin-bottom: 20px;
}

.latest-newsletter-embed,
.latest-newsletter-blurb {
  padding: 10px;
}

.latest-newsletter-item .latest-newsletter-blurb {
  font-size: 20px;
}
.latest-embed-blurb-div .latest-newsletter-embed {
  border-style: solid !important;
  border-color: transparent;
}

.latest-embed-blurb-div .latest-newsletter-blurb {
  background-color: #a8a6cd;
  text-shadow: 1px 1px 1px #0e2b54;
  color: #0e2b54;
  font-family: "Baskervville SC";
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  padding-top: 20px;
  font-size: 1.75rem;
  padding-bottom: 30px;
  border-bottom: 3px ridge #0e2b54;
}

.latest-newsletter-blurb {
  background-color: transparent !important;
}

/* .newsletter-item {
  padding: 1rem;
  gap: 20px;
  border: 10px black;
}

.newsletter-month {
  font-size: 2rem;
  font-family: "Playfair Display", serif;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444484;
}

.newsletter-theme {
  font-size: 2rem;
  font-family: "Playfair Display", serif;
  font-weight: 500;
  color: #444484;
  margin-bottom: 10px;
}

.newsletter-item .newsletter-blurb {
  font-family: Lora;
  padding: 10px;
  border-style: inset;
  border-color: white;
  background-color:#444484;
  color: white;
  border-radius: 25px;
  font-weight: 300;
  text-align: justify, center;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 20px;
} */

.newsletter-link {
  display: inline-block; /* Ensures link behaves as a block for spacing */
  font-size: 1.5rem; /* Normal font size */
  color: #444484; /* Primary color for link */
  text-decoration: none; /* Remove underline */
  margin-top: 10px;
}

.newsletter-link:hover {
  text-decoration: underline; /* Add underline on hover */
  color: #444484; /* Darker shade for hover effect */
}

.newslettersTitle {
  padding: 20px;
  color: #0e2b54 !important;
}
.newslettersTitle:hover {
  font-size: 4rem;
  padding: 20px;
  color: #0e2b54 !important;
}

.old-newsletters,
.latest-embed-blurb-div {
  background-color: transparent;
  border-style: solid;
  border-color: #444484;
  border-width: 5px;
  border-radius: 30px;
}

.old-newsletters:hover,
.latest-embed-blurb-div:hover {
  border-style: solid !important;
}

.newsletter-title {
  padding-top: 2rem;
  font-family: "Baskervville SC" !important;
  color: #0e2b54;
  text-shadow: 1px 1px 1px #0e2b54;
}

.newsletter-blurb {
  font-family: "Baskervville SC" !important;
  padding: 30px;
  font-size: 1.5rem !important;
  text-shadow: 1px 1px 1px #0e2b54;
  border-bottom: 3px ridge #0e2b54;
}

.old-newsletters .newsletter-title {
  padding-top: 0;
  font-family: "Baskervville SC" !important;
  color: #0e2b54;
  width: 50rem;
}

.newsletter-title-blurb {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}

.newsletter-blurb {
  color: #0e2b54;
}

.newsletter-item {
  padding: 20px;
  border-top: 5px solid #0e2b54;
}

.module {
  padding: 10px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
