@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Hanunoo&display=swap");

* {
  box-sizing: border-box;
}

.error-title {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 430px) {
  .error-title {
    font-size: 3em !important;
  }
}

.error-page {
  background-color: #d2d0fa;
  background-position: center;
  background-size: cover;
  color: #0e2b54;
  min-height: 60em; /* Allows the content to extend beyond the viewport */
  width: 100vw;
  overflow-x: hidden; /* Prevents horizontal overflow */
  overflow-y: auto; /* Allows vertical scrolling */
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 5rem;
}

.navbar {
  position: relative;
  width: 100%;
  z-index: 1;
}
