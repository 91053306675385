@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC:wght@400;700&display=swap");

.slideshow {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carousel {
  padding-top: 50px;
  border-style: solid;
  border-width: 8px;
  border-radius: 50px;
  border-color: transparent;
  width: 100%;
  max-width: 70em;
  z-index: 0;
  height: 700px;
}

.image-container img {
  max-width: 70em;
  width: 100%;
  height: 700px;
  object-fit: contain;
}

.image-container .caption {
  text-shadow:
    2px 2px 0 #000,
    -2px 2px 0 #000,
    -2px -2px 0 #000,
    2px -2px 0 #000;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.5rem;
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0e2b54;
  color: white;
  display: inline;
  overflow: hidden;
}

@media screen and (max-width: 430px) {
  .image-container img {
    height: 20em;
    width: 25em;
  }

  .image-container .caption {
    font-size: 1rem;
    bottom: 15px;
  }

  .slideshow {
    margin-left: 7px;
    height: 15em;
    margin-bottom: 4em;
  }
}
