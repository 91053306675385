.footer {
  font-weight: 400;
  padding-top: 10px;
  background-color: #ede8f5;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #444484;
  -webkit-text-stroke: 0.5px #444484;
  border-top: 3px solid rgb(255, 255, 255);
  overflow: hidden;
}

.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0;
}

.icons {
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 0;
}

.logo-ref img {
  height: 25px;
  width: 25px;
  margin: 0;
  padding: 0px;
}

.developer-columns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
}

.developer-column {
  text-align: center;
  padding: 0 20px; /* Adjust the padding for spacing between text and divider */
}

.divider {
  width: 2px;
  background-color: #ede8f4;
  height: 5em; /* Explicitly set a height for the divider */
  z-index: 1;
  align-self: stretch; /* Ensures the divider takes the full height of the container */
}

@media screen and (max-width: 430px) {
  .contact-box {
    padding-left: 2em;
  }
}
