@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.schedules-specifics {
  display: flex;
  flex-direction: row; /* Stack children vertically */
}

.day-type {
  margin: 4px 0; /* Space between items within left and right schedules */
  -webkit-text-stroke: 0.5px black;
  font-size: 1em;
}

.left-schedule,
.right-schedule {
  width: 50%; /* Each takes up half of the container's width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  display: flex;
  flex-direction: column; /* Stack contents vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.left-schedule {
  text-align: center; /* Center text horizontally */
  padding-right: 8px; /* Space between left and right schedules */
}

.right-schedule {
  text-align: center; /* Center text horizontally */
  padding-left: 8px; /* Space between left and right schedules */
}

.period-name,
.period-time {
  margin: 4px 0; /* Space between items within left and right schedules */
  -webkit-text-stroke: 0.5px #0e2b54;
}

.period-name {
  font-size: 1em;
}

.period-time {
  font-size: 1.5em;
}

@media screen and (max-width: 430px) {
  .day-type {
    font-size: 0.82em;
  }

  .period-name {
    font-size: 0.55em;
  }

  .period-time {
    font-size: 0.82em;
  }
}

.time {
  padding-top: 25px;
}

.time-into-container,
.time-left-container {
  padding: 0.25em;
}

.time-left,
.time-into {
  font-family: "Lora" !important;
  font-size: 0.75em !important;
  padding-right: 1.1em;
}
