@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");

.fundraisingTitleCaption {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fundraisingImg {
  display: flex;
  flex-direction: row;
}
.jpromImage {
  height: 35em;
  margin-right: 10em;
  margin-top: 0em;
  max-width: 100em;
}
.jprom-page {
  background-image: url(../../public/images/backgroundJPROM.svg);
  background-position: center;
  background-size: cover;
  color: #0e2b54;
  min-height: 100vh; /* Allows the content to extend beyond the viewport */
  width: 100vw;
  overflow-x: hidden; /* Prevents horizontal overflow */
  overflow-y: auto; /* Allows vertical scrolling */
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
}
.fundRaising {
  display: flex;
  justify-content: center;
  overflow: hidden;
  text-wrap: nowrap;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the text */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width calculation */
  overflow-wrap: break-word; /* Ensure long words break onto the next line */
}

.navbar {
  position: relative;
  width: 100%;
  z-index: 1;
}

.content {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  width: 100%;
}

.caption {
  border-style: inset;
  border-color: #444484;
  border-radius: 25px;
  padding: 30px;
  background-color: #0e2b54;
  font-weight: 400;
  border-width: 3px;
  margin-bottom: 300px;
  color: white;
  font-size: 30px;
  max-width: 75%;
  line-height: 50px;
}

.caption-container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.firstEvent {
  width: 100%; 
  max-width: 40em; 
  height: auto;
  align-self: center;
  margin-top: 0;
  margin-bottom: 1em;
  margin-right: .5em;
  margin-left: .5em;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.01em;
}
.desc {
  font-size: 1.2rem;
  line-height: 1.4em;
  margin-bottom: 1em;
  margin-top: 0;
}

@media screen and (max-width: 430px) {
  .fundraisingImg {
    flex-direction: column;
    align-items: right;
    margin: 0 auto; /* Center the content */
  }

  .jpromImage {
    width: 100%; /* Ensure the image fits within the container */
    max-width: 20em; /* Set a maximum width */
    height: auto;
    align-self: center;
    margin-top: 0;
    margin-bottom: 1em;
    margin-left: 15em;
    margin-right: 15em;
  }

  .fundRaising,
  .header {
    display: flex;
    justify-content: center;
    font-size: 1.5rem !important; /* Smaller font size */
    width: 100%; /* Ensure the text fits within the container */
    padding: 0 10px; /* Add horizontal padding */
    text-align: center; /* Center align text */
  }

  .caption-container {
    padding: 10px; /* Adjust padding to fit within screen */
    padding-top: 25px;
    width: 100%; /* Ensure container fits within the screen */
    max-width: 30em;
  }

  .caption {
    font-size: 1.2rem; /* Smaller font size */
    line-height: 1.4em; /* Adjust line height for readability */
    padding: 10px; /* Reduce padding */
    max-width: 75%; /* Ensure the caption fits within the container */
    margin-bottom: 30px; /* Adjust bottom margin */
    text-align: center; /* Center align text */
  }
  .firstEvent {
    width: 100%; /* Ensure the image fits within the container */
    max-width: 20em; /* Set a maximum width */
    height: auto;
    align-self: center;
    margin-top: 0;
    margin-bottom: 1em;
    margin-left: 15em;
    margin-right: 15em;
  }
}
