@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  text-align: center;
}

.about-page {
  background-color: white;
  color: #0e2b54;
  min-height: 100vh; /* Allows the content to extend beyond the viewport */
  width: 100vw;
  overflow-x: hidden; /* Prevents horizontal overflow */
  overflow-y: auto; /* Allows vertical scrolling */
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 5rem;
}

.navbar {
  position: relative;
  width: 100%;
  z-index: 1;
  padding-bottom: 50px;
}

.content {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
}

.box-IT {
  justify-content: center;
  display: flex;
  margin-top: 16px; /* Adds space between the title and the boxes */
}

.box-item {
  display: flex;
  flex-wrap: wrap; /* Allows sub-boxes to wrap to the next line if they exceed the container's width */
  gap: 16px;
  margin-top: 30px; /* Adds space between the title and the boxes */
}

.sub-box {
  display: inline-flex;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1 1 calc(50% - 20px); /* Each sub-box takes up 50% of the container's width minus the gap */
  max-width: 50%; /* Limits the width of each sub-box */
  border: 10px black;
}

.sub-box img {
  max-width: 100%;
  /* Ensures the image can shrink to fit the container */
  height: auto;
  /* Maintains the aspect ratio */
  max-height: 500px;
  /* Limits the height of the images */
  object-fit: contain;
  /* Ensures the entire image is visible within its container */
}

.content .title-typography {
  font-family: Lora;
  font-weight: 400;
  margin-top: 6rem;
}

.chiefStaff,
.internals,
.it,
.graphics,
.finance,
.events,
.outreach {
  margin-top: 30px; /* Adds space between the title and the boxes */
}

.presidents .copresidents {
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  animation: typing 2s steps(250, end);
  font-family: Lora;
  margin: 0 auto;
  font-weight: 400;
  margin: auto;
  white-space: nowrap;
  font-size: 100px;
}

.sub-box .name-typography {
  font-weight: 900;
  margin-top: 0.5rem;
  text-shadow: 1px 1px 0 #000;
}

.sub-box .typography {
  padding: 20px;
  border-color: white;
  background-color: #0e2b54;
  color: white;
  border-radius: 25px;
  font-family: Lora;
  font-weight: 300;
  text-align: justify, center;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 20px;
}

.copresidents,
.title-typography {
  border-top: 5px ridge #0e2b54;
  border-bottom: 5px ridge #0e2b54;
  border-left: none;
  border-right: none;
}

@keyframes typing {
  from {
    width: 0;
  }
}

.backgroundInsert3 {
  background-image: url(../../public/images/svg5.svg);
  margin-left: -1.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.backgroundInsert4 {
  background-image: url(../../public/images/svg6.svg);
  margin-left: -1.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.backgroundInsert5 {
  background-image: url(../../public/images/svg7.svg);
  margin-left: -1.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.backgroundInsert6 {
  background-image: url(../../public/images/svg8.svg);
  margin-left: -1.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.backgroundInsert7 {
  background-image: url(../../public/images/svg9.svg);
  margin-left: -2.5em;
  margin-bottom: -1.1em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.backgroundInsert8 {
  background-image: url(../../public/images/svg10.svg);
  margin-left: -2.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

.backgroundInsert9 {
  background-image: url(../../public/images/svg11.svg);
  margin-left: -2.5em;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: show;
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}

@media screen and (max-width: 430px) {
  .sub-box .name-typography {
    font-weight: 700;
    margin-top: 0.5rem;
    text-shadow: 0px 0px 0 #000;
    text-wrap: nowrap;
  }

  .events,
  .internals,
  .it,
  .finance,
  .graphics,
  .outreach {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .copresidents,
  .title-typography {
    border-top: 5px ridge #0e2b54;
    border-bottom: 5px ridge #0e2b54;
    border-left: none;
    border-right: none;
    max-width: 80%;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .presidents .copresidents {
    font-size: 3rem;
  }

  .sub-box img {
    max-width: 100%;
    min-width: 300px;
    height: auto;
    max-height: 500px;
    object-fit: contain;
  }

  .sub-box {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border: 10px black;
    align-items: center;
    justify-content: center;
  }

  .box-item {
    display: flex;
    margin-top: 30px; /* Adds space between the title and the boxes */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    flex-wrap: nowrap;
  }

  .sub-box .typography {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px;
    margin-left: 3rem;
    margin-right: 3rem;
    min-width: 370px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    width: 20%;
  }

  .content .title-typography {
    font-family: Lora;
    font-weight: 400;
    font-size: 3rem;
    margin: 0rem;
  }

  .chiefStaff {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .backgroundInsert3,
  .backgroundInsert4,
  .backgroundInsert5,
  .backgroundInsert6,
  .backgroundInsert7,
  .backgroundInsert8,
  .backgroundInsert9 {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}
