@import url("https://fonts.googleapis.com/css2?family=Baskervville+SC&display=swap");

.freshman,
.sophomore,
.junior {
  background-color: transparent;
}

.freshman:hover,
.sophomore:hover,
.junior:hover {
}

.magazineTitle,
.monthTitle {
  font-family: "Baskervville SC" !important;
  color: #ffffff;
  padding: 20px;
}

.magazineTitle {
  font-size: 100px;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #0e2b54;
  background-color: rgb(255 255 255 / 25%);
  border-style: solid;
  border-radius: 30px;
  border-color: #0e2b54;
  border-width: 5px;
}

.junior .magazineTitle {
  font-size: 50px;
  text-shadow: 1px 1px 2px #0e2b54;
}
.sophomore .magazineTitle {
  font-size: 50px;
  text-shadow: 1px 1px 2px #0e2b54;
}
.freshman .magazineTitle {
  font-size: 50px;
  text-shadow: 1px 1px 2px #0e2b54;
}
.magazineTitle:hover {
  background-color: #a8a6cd;
  transform: scale(1.04);
}

.dropdown-item {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
}
.dropdown-item-container {
  padding: 20px;
  border-radius: 1em;
  border-style: solid;
  border-width: 0.5em;
  border-color: #0e2b54;
}

.monthTitle {
  font-family: "Baskervville SC" !important;
  text-shadow: 1px 1px 2px #a8a6cd;
  color: #ffffff;
  border-width: 3px;
  border-radius: 30px;
  width: 37.5rem;
}

.magazine-pdf {
  padding-top: 15px;
}

@media (max-width: 1440px) {
  .dropdown-item {
    width: 100%; 
    height: auto; 
    padding: 50px 25px 25px 25px;
  }
}